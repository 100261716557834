import { IAttachment, ISearch, ISearchRequest } from '@/modules/system/types';
import { DamageMode, IDamage, IManipulation, IResearch } from '../clinic/types';

export interface IToothSurface {
    type: string;
    cure: string;
    damage: boolean;
    processing: boolean;
}

export interface IToothSurfaces {
    M: IToothSurface;
    D: IToothSurface;
    V: IToothSurface;
    L: IToothSurface;
    O: IToothSurface;
}

export interface IToothDamage {
    id: number;
    code: string;
    name: string;
    damageId: number | null;
    damageGum: boolean;
    damageSurface: boolean;
    mode: DamageMode;
    number: number;
    surfaces: string[];
    processing: boolean;
    appended: boolean;
    damage: IDamage | undefined;
}

export interface ITooth {
    toothId: number;
    patientId: number;
    number: number;
    type: string;
    gumLevel: number;
    roots: string;
    cure: string;
    surfaces: IToothSurfaces;
    crownMaterial: string;
    position: string;
    order: number;
    damages: Array<IToothDamage>;
    processing: boolean;
}

export interface ISurfaceAction {
    tooth: ITooth;
    surface: IToothSurface;
}

export interface IToothMarker {
    damageCode: string;
    patientId: number;
    toothNumber: number;
    cure: string;
    surface: string;
}

export interface IPatientPhoto extends IAttachment {
    patientId: number;
    type: number;
    typeName: string;
    typeBackground: string;
    archived: boolean;
    default: boolean;
    processing: boolean;
}

export interface ICuringPlan {
    id: number | null;
    name: string;
    state: string;
    patientId: number;
    author: number | null;
    details: string;
    created: Date | null;
    archived: Date | null;
    manipulations: Array<IManipulation>
    processing: boolean;
}

export interface IVisitPayment {
    payType: string | null;
    amount: number;
}
export interface IVisit {
    id: number | null;
    clinicId: number | null;
    state: string;
    cancelCode: any;
    place: number | null;
    patientId: number | null;
    patientName: string | null;
    patientPhone: string | null;
    patientBirthday: Date | null;
    photo: string | null;
    doctor: number | null;
    visitDate: string;
    timeFrom: string | null;
    timeTo: string | null;
    visitPayed: string | null,
    totalAmount: number;
    payedAmount: number;
    originalAmount: number;
    prepaid: number;
    totalDebt: number;
    discount: number;
    payType: string | null;
    motive: string;
    notes: string;
    smsSent: Date | null;
    manipulations: Array<IManipulation>
    processing: boolean;
}

export interface IAuditRecord {
    id: number | null;
    date: string;
    companyId: number;
    userId: number;
    userName: string;
    objectType: string;
    objectId: string;
    patientId: number;
    patientName: string;
    data: string;
}

export interface IPatient {
    patientId: number | null;
    patientParent: number | null;
    patientVIP: number;
    patientFirstName: string;
    patientLastName: string;
    patientPhone: string;
    patientCode: string;
    patientGroups: string;
    languageCode: string;
    patientBirthday: Date | null;
    lastVisit: Date;
    patientDoctorName: string;
    patientAttrator: number | null;
    photo: string | null;
    state: number;
    curingVisit: number;
    selectedPlan: number | null;
    patientSMS: boolean;
    photos: Array<IPatientPhoto>;
    teeth: Array<ITooth>;
    plans: Array<ICuringPlan>;
    visits: Array<IVisit>;
    childs: Array<IPatient>;
    researches: Array<IResearch>;
    audit: Array<IAuditRecord>;
    processing: boolean;
}

export interface IPatientFilter extends ISearchRequest {
    patientName: string;
    doctorName: string;
    patientPhone: string;
}

export interface IPatientSearch extends ISearch<IPatientFilter, IPatient> {
}

export enum CustomerLinks {
    CustomersList = 'CustomersList',
    CustomerEdit = 'CustomerEdit'
}
